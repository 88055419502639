import React from 'react';
import { graphql } from 'gatsby';

import { MetricsPageTemplate } from '../templates';
import { Layout } from '../components';

const MetricsPage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
    return (
        <Layout title={ frontmatter.headerTitle }>
            <MetricsPageTemplate { ...frontmatter }/>
        </Layout>
    );
};

export default MetricsPage;

export const pageQuery = graphql`
    query MetricsPageQuery {
        markdownRemark(frontmatter: { templateKey: { eq: "metrics-page" } }) {
            frontmatter {
                headerImage {
                    cloudinaryMedia
                    assetType
                    deliveryType
                    urlRemainder
                    sourceURL
                }
                headerTitle
                heroTitle
                heroSubtitle
                pillars {
                    name
                    icon {
                        props {
                            children {
                                key
                                props {
                                    d
                                    points
                                    strokeWidth
                                    strokeLinejoin
                                    strokeLinecap
                                    fillRule
                                    clipRule
                                }
                                type
                            }
                            attr {
                                fill
                                stroke
                                strokeLinecap
                                strokeLinejoin
                                strokeWidth
                                viewBox
                            }
                        }
                    }
                    metrics {
                        name
                        submetrics {
                            value
                            unit
                            comment
                        }
                    }
                }
            }
        }
    }
`;